import * as React from "react"
import Layout from "../../components/Layout"
import Elements from "./elements/Elements"
import { deentitize } from "../../helpers/common"
import Subscribe from "../../components/Subscribe"
import Banner from "../../components/Banner"
import { Helmet } from 'react-helmet'

const ContentBuilder = ({ pageContext: { page, nodes } }) => {
  const elementalContent = page.elementalContent ? deentitize(page.elementalContent) : null
  const hasBanner = page.bannerImage && page.bannerImage.absoluteLink
  return (
    <Layout>
        <Helmet>
             <title>{ page.title } | Grocorp Furnitures</title>
        </Helmet>
      {hasBanner && (
        <Banner title={page.title} imageURL={page.bannerImage.absoluteLink} />
      )}
      {elementalContent && (
      <Elements data={elementalContent} />
        )}
      <Subscribe />
    </Layout>
  )
}

export default ContentBuilder
